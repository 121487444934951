import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Spinner } from '@amzn/awsui-components-react';
import { useHistory, useLocation, useRouteMatch, Link } from 'react-router-dom';

import DetailsTab from './DetailsTab/DetailsTab';
import CustomersTab from './CustomersTab/CustomersTab';
import InvoicesTab from './InvoicesTab/InvoicesTab';
import NotesTab from './NotesTab/NotesTab';
import HistoryTab from './HistoryTab/HistoryTab';
import AuditTab from '../../../../common/components/AuditTrail/AuditTab';

import {
    getSelectedActivity,
    selectError,
    selectSelectedActivity,
    selectIsLoading,
    setSelectedActivity,
} from '../../../store/slices/selectedActivitySlice';
import { AJAX_CALL_ERROR } from '../../../../common/constants/grimsby';
import { formatString } from '../../../../common/utils/formatString';
import { Actions } from '../../../../common/constants/auth';
import { check, getUserRoles } from '../../../../common/utils/auth';
import {
    ActivityStatus,
    ActivityType,
    AudienceType,
    isACI,
} from '../Common/Common';
import { ActivityData } from '../../../interfaces/activity';
import {
    Alert,
    Box,
    Button,
    FormField,
    Header,
    Input,
    Modal,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import Can from '../../../../common/components/Can';
import {
    getActivities,
    getActivityById,
    mergeActivityIntoActivity,
} from '../../../services/activity-service';
import MergeActivityList from '../../Common/MergeActivity/MergeActivityList';
import { useNotifications } from '../../../../common/context/grimsbyNotifications';
import './ActivityDetails.scss';
import { selectFeatures } from '../../../../common/store/slices/featureSlice';
import { checkFeature } from '../../../../common/utils/featureFlag';

const AudienceTypeCustomerMap: ReadonlyMap<AudienceType, boolean> = new Map([
    [AudienceType.Public, true],
    [AudienceType.PartnerPublic, false],
    [AudienceType.PartnerPublicUnlisted, false],
    [AudienceType.CommercialPrivate, true],
    [AudienceType.CommercialSemiPrivate, true],
    [AudienceType.PartnerSemiPrivate, true],
    [AudienceType.PartnerPrivate, true],
    [AudienceType.Internal, false],
]);
const ActivityDetails = () => {
    const match = useRouteMatch<{ id: string }>();
    const error = useSelector(selectError);
    const isLoading = useSelector(selectIsLoading);
    const activity = useSelector(selectSelectedActivity);
    const featureFlags = useSelector(selectFeatures);
    const dispatch = useDispatch();
    const roles = getUserRoles();
    const location = useLocation();
    const history = useHistory();
    const [showMergeModal, setShowMergeModal] = useState(false);
    const [mergeActivities, setMergeActivities] = useState<Array<any>>([]);
    const [mergeActivity, setMergeActivity] = useState<ActivityData | null>(
        null,
    );
    const [showConfirmMerge, setShowConfirmMerge] = useState(false);
    const [isMerging, setIsMerging] = useState(false);
    const [confirmMergeValue, setConfirmMergeValue] = useState<string>('');
    const [confirmMergeError, setConfirmMergeError] = useState<string | null>(
        null,
    );
    const [isLoadingMergeList, setIsLoadingMergeList] = useState<boolean>(true);
    const [showMustSelectActivityForMerge, setShowMustSelectActivityForMerge] =
        useState(false);

    const [activeTabId, setActiveTabId] = useState('details');

    const CONFIRM_MERGE_STRING = 'CONFIRM MERGE';
    // TODO: Clean up feature flag: https://sim.amazon.com/issues/V1584942032
    const canShowClassroomsComponents = checkFeature(
        '',
        { featureName: 'classrooms-components' },
        featureFlags?.features,
    );
    const isACIFeatureEnabled = checkFeature(
        '',
        { featureName: 'aci_support' },
        featureFlags?.features,
    );
    const canShowWaitlistEnabledComponent = checkFeature(
        '',
        { featureName: 'waitlist-option-select' },
        featureFlags?.features,
    );

    const { addNotification } = useNotifications();

    const shouldShowInvoicesTab = (activityData: ActivityData) => {
        // we need to show invoices tab only for
        // public or private onsite classes and for Commercial programs only
        const hasActivityTypeEquality =
            activityData.activity_type === ActivityType.Class;
        const isPublicAccessible =
            activityData.activity_audience === AudienceType.Public;
        const isCommercialPrivateAccessible =
            activityData.activity_audience === AudienceType.CommercialPrivate ||
            activityData.activity_audience ===
                AudienceType.CommercialSemiPrivate;
        const isPublicOrComercialPrivateAccessible =
            isPublicAccessible || isCommercialPrivateAccessible;
        const isCommercialProgram = activityData.program === 'Commercial';
        const isDedicatedCloudProgram =
            activityData.program === 'Dedicated Cloud';

        if (
            (hasActivityTypeEquality &&
                isPublicOrComercialPrivateAccessible &&
                isCommercialProgram) ||
            (hasActivityTypeEquality &&
                isCommercialPrivateAccessible &&
                isDedicatedCloudProgram)
        ) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        dispatch(getSelectedActivity(match.params.id));
    }, [dispatch, match.params.id]);

    useEffect(() => {
        if (location.search) {
            const urlParams = new URLSearchParams(location.search);
            handleSetActiveTab(urlParams.get('tabId'));
        }
    }, [activeTabId, history]);

    const handleActivityDetailsUpdated = async () => {
        const activityData = await getActivityById(match.params.id);

        if (activityData?.activity) {
            dispatch(setSelectedActivity(activityData));
        }
    };

    const getMergeActivities = async () => {
        if (activity?.activity) {
            setShowMustSelectActivityForMerge(false);
            setIsLoadingMergeList(true);
            const activityData = activity.activity;
            const startDeliverySession =
                activityData.delivery_sessions[0].start_timestamp;
            const endDeliverySession =
                activityData.delivery_sessions[
                    activityData.delivery_sessions.length - 1
                ].end_timestamp;

            const activityResponse = await getActivities({
                delivery_country: [activityData.delivery_country],
                delivery_city: [activityData.delivery_city],
                course_name: [activityData.course_name],
                activity_modality: [activityData.activity_modality],
                delivery_language: [activityData.delivery_language],
                start_timestamp: startDeliverySession,
                end_timestamp: endDeliverySession,
            });

            setMergeActivities(
                activityResponse.activities.filter((a: any) => {
                    return a.pk !== activity.activity.pk;
                }),
            );

            setIsLoadingMergeList(false);
        }
    };

    const mergeActivityFormProps: any = {
        mergeActivities,
        setMergeActivity,
        autoMergeOn: true,
        mergeDescription: (
            <span>
                Merging this activity adds the customer(s) from this activity to
                an existing activity with the same course, language, location,
                modality and start date.
            </span>
        ),
        activityPk: activity?.activity.pk,
        showMustSelect: showMustSelectActivityForMerge,
        isLoadingMergeList,
    };

    const confirmMergeActivity = async () => {
        if (confirmMergeValue !== CONFIRM_MERGE_STRING) {
            setConfirmMergeError(`This field is required to merge.`);
            return;
        } else {
            setConfirmMergeError(null);
        }

        if (activity?.activity && mergeActivity) {
            setIsMerging(true);
            const isSuccessful = await mergeActivityIntoActivity(
                mergeActivity.pk,
                activity.activity.pk,
            );
            setIsMerging(false);

            setShowConfirmMerge(false);
            setShowMergeModal(false);

            addNotification({
                id: `merge-activity`,
                ...(isSuccessful
                    ? {
                          type: 'success',
                          content: <span>Activity merged.</span>,
                      }
                    : {
                          type: 'error',
                          content:
                              'An error occurred while merging the activity.',
                      }),
            });

            if (isSuccessful) {
                history.push({
                    pathname: `/activities/${mergeActivity.pk}`,
                });
            }
        }
    };

    const submitMerge = () => {
        if (!mergeActivity) {
            setShowMustSelectActivityForMerge(true);
        } else {
            setShowConfirmMerge(true);
        }
    };

    const handleSetActiveTab = (param) => {
        const params = new URLSearchParams({ tabId: param });
        history.push({
            search: `?${params.toString()}`,
        });
        setActiveTabId(param);
    };

    if (error) {
        return (
            <Alert
                data-testid="ActivityDetailsError"
                header="Error"
                type="error"
            >
                {AJAX_CALL_ERROR}
            </Alert>
        );
    } else if (!isLoading && activity?.activity) {
        const activityData = activity.activity;
        const shouldShowCustomerTab = !!AudienceTypeCustomerMap.get(
            activityData.activity_audience as AudienceType,
        );
        const isAciActivity = isACI(
            activityData.activity_group_name,
            isACIFeatureEnabled,
        );
        return (
            <div data-testid="ActivityDetailsPage">
                {activity.activity.activity_status ===
                    ActivityStatus.Canceled && (
                    <Alert
                        onDismiss={() => false}
                        visible={true}
                        header="This activity has been cancelled"
                        data-testid="CancelledActivityAlert"
                    >
                        Information is displayed here for historical reference
                        and cannot be edited.
                    </Alert>
                )}
                <Header
                    variant="h1"
                    actions={
                        <Can
                            perform={Actions.ACTIVITY_MODIFY}
                            yes={() => (
                                <SpaceBetween direction="horizontal" size="xs">
                                    {isAciActivity && (
                                        <Button
                                            data-testid="EditGroupButton"
                                            onClick={() => {
                                                history.push({
                                                    pathname: `/activities/group/${activityData.activity_group_name}/edit`,
                                                });
                                            }}
                                        >
                                            Edit Group
                                        </Button>
                                    )}
                                    <Button
                                        data-testid="MergeActivityButton"
                                        onClick={() => {
                                            setShowMergeModal(true);
                                            getMergeActivities();
                                        }}
                                    >
                                        Merge activity
                                    </Button>
                                    <Link
                                        to={`/activities/${activityData.pk}/clone`}
                                    >
                                        <Button data-testid="CloneActivityButton">
                                            Clone activity
                                        </Button>
                                    </Link>
                                </SpaceBetween>
                            )}
                        />
                    }
                >
                    <span data-testid="ActivityTitle">
                        {formatString(activityData.activity_name)}
                    </span>
                </Header>
                <Modal
                    size={'max'}
                    visible={showMergeModal}
                    onDismiss={() => {
                        setShowMergeModal(false);
                    }}
                    header={'Merge activity'}
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button
                                    variant="link"
                                    onClick={() => {
                                        setShowMergeModal(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        submitMerge();
                                    }}
                                    disabled={mergeActivities.length === 0}
                                >
                                    Merge activity
                                </Button>
                            </SpaceBetween>
                        </Box>
                    }
                >
                    <Modal
                        size={'medium'}
                        visible={showConfirmMerge}
                        onDismiss={() => {
                            setShowConfirmMerge(false);
                        }}
                        header={'Merge activity?'}
                        footer={
                            <Box float="right">
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button
                                        variant="link"
                                        onClick={() => {
                                            setShowConfirmMerge(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="primary"
                                        disabled={isMerging}
                                        onClick={() => {
                                            confirmMergeActivity();
                                        }}
                                    >
                                        {isMerging
                                            ? 'Merging activity...'
                                            : 'Merge'}
                                    </Button>
                                </SpaceBetween>
                            </Box>
                        }
                    >
                        <p>Merge this activity with the selected activity?</p>
                        <div className="grimsby-sub-section-divider" />
                        <FormField
                            label={`To continue, enter "CONFIRM MERGE" and press the merge button.`}
                            errorText={confirmMergeError}
                        >
                            <Input
                                value={confirmMergeValue}
                                placeholder="Enter confirmation message"
                                onChange={(e) => {
                                    setConfirmMergeValue(e.detail.value);
                                }}
                            />
                        </FormField>
                    </Modal>
                    <MergeActivityList {...mergeActivityFormProps} />
                </Modal>
                <Tabs
                    data-testid="ActivityDetailsPageTabSection"
                    tabs={[
                        {
                            label: 'Details',
                            id: 'details',
                            content: (
                                <DetailsTab
                                    activity={activityData}
                                    onActivityDetailsUpdated={
                                        handleActivityDetailsUpdated
                                    }
                                    canShowClassroomsComponents={
                                        canShowClassroomsComponents
                                    }
                                    isACIFeatureEnabled={isACIFeatureEnabled}
                                />
                            ),
                        },
                        ...(check(roles, Actions.ACTIVITY_CUSTOMERS_VIEW) &&
                        shouldShowCustomerTab
                            ? [
                                  {
                                      label: 'Customers',
                                      id: 'customers',
                                      content: (
                                          <CustomersTab
                                              activity={activityData}
                                          />
                                      ),
                                  },
                              ]
                            : []),
                        ...(check(roles, Actions.ACTIVITY_INVOICES_VIEW) &&
                        shouldShowInvoicesTab(activityData)
                            ? [
                                  {
                                      label: 'Invoices',
                                      id: 'invoices',
                                      content: (
                                          <InvoicesTab
                                              activity={activityData}
                                          />
                                      ),
                                  },
                              ]
                            : []),
                        ...(check(roles, Actions.ACTIVITY_NOTES_VIEW)
                            ? [
                                  {
                                      label: 'Notes',
                                      id: 'notes',
                                      content: (
                                          <NotesTab
                                              id={match.params.id}
                                              activity={activityData}
                                          />
                                      ),
                                  },
                              ]
                            : []),
                        ...(check(roles, Actions.ACTIVITY_HISTORY_VIEW)
                            ? [
                                  {
                                      label: 'History',
                                      id: 'history',
                                      content: (
                                          <HistoryTab activity={activityData} />
                                      ),
                                  },
                              ]
                            : []),
                        ...(check(roles, Actions.ACTIVITY_AUDIT_VIEW)
                            ? [
                                  {
                                      label: 'Audit Log',
                                      id: 'auditLog',
                                      content: (
                                          <AuditTab id={match.params.id} />
                                      ),
                                  },
                              ]
                            : []),
                    ]}
                    activeTabId={activeTabId}
                    onChange={(e) => {
                        handleSetActiveTab(e.detail.activeTabId);
                    }}
                />
            </div>
        );
    } else {
        return <Spinner data-testid="ActivityDetailsSpinner" />;
    }
};

export default ActivityDetails;
